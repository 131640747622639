<div class="wrapper">
  <div
    class="container"
    *ngIf="studentAppeals && imageMessages; else loadingSpinner"
  >
    <!-- Left Pane (List of Appeals) -->
    <div class="inbox" *ngIf="studentAppeals.length > 0">
      <div class="search">
        <button type="button" (click)="filterResults(filter.value)">
          <mat-icon
            class="search-icon"
            aria-hidden="false"
            aria-label="Example home icon"
            fontIcon="search"
          ></mat-icon>
        </button>

        <input
          type="text"
          placeholder="Search for Appeal"
          #filter
          (keyup.enter)="filterResults(filter.value)"
          (input)="onInputChange(filter.value)"
        />
      </div>
      <div
        class="inbox-item"
        *ngFor="let appeal of filteredAppeals"
        (click)="selectAppeal(appeal)"
        [class.selected-appeal]="appeal === currentAppeal"
      >
        <div class="left-section">
          <p class="inbox-name">
            <b>
              {{ appeal?.professor_first_name }}
              {{ appeal?.professor_last_name }}
            </b>
          </p>
          <p class="inbox-course-name">
            {{ appeal.course_name }}
          </p>
          <p class="inbox-assignment-name">{{ appeal?.assignment_name }}</p>
        </div>
        <div class="right-side">
          <div class="inbox-item-timestamp-dot-container">
            <p class="inbox-timestamp">
              {{ formatTimestamp(appeal.created_at).time }}
            </p>
            <div
              [ngClass]="{
                'unread-dot': appeal.is_read === false,
                'no-dot': appeal.is_read !== false
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Right Pane (Selected Appeal Details) -->
    <div class="right-pane" *ngIf="imageMessages.length > 0">
      <div class="card">
        <mat-toolbar class="top-bar">
          <mat-toolbar-row>
            <span class="header student-name">
              {{ currentAppeal.professor_first_name }}
              {{ currentAppeal.professor_last_name }}
            </span>
            <span class="header assignment-name">{{
              currentAppeal.assignment_name
            }}</span>
          </mat-toolbar-row>
        </mat-toolbar>
        <div class="chat-list" #chatListContainer>
          <ng-container *ngFor="let chat of imageMessages; let i = index">
            <ng-container
              *ngIf="
                i === 0 ||
                !isSameDate(chat.created_at, imageMessages[i - 1].created_at)
              "
            >
              <div class="section-date">
                {{ formatTimestamp(chat.created_at).date }}
              </div>
            </ng-container>
            <div
              class="chat-item"
              [ngClass]="{
                right: chat?.sender_id == student.id,
                left: chat?.sender_id != student.id,
                lightblue: chat?.from_grader == true,
                notification: chat.message_text
                  .trim()
                  .startsWith('Notification:')
              }"
            >
              <div
                class="inbox-timestamp"
                *ngIf="!chat.message_text.trim().startsWith('Notification:')"
              >
                <div
                  *ngIf="
                    i === 0 ||
                    chat.sender_id !== this.imageMessages[i - 1]?.sender_id
                  "
                >
                  <p class="name">{{ chat.sender_name }}</p>
                </div>
                {{ formatTimestamp(chat.created_at).time }}
              </div>
              <div class="message-content">
                <div
                  *ngIf="!chat.message_text.trim().startsWith('Notification:')"
                ></div>

                <div
                  *ngIf="!chat.message_text.trim().startsWith('Notification:')"
                  id="message-body"
                >
                  <p class="message-text">{{ chat?.message_text }}</p>
                </div>

                <div class="image-wrapper">
                  <img
                    *ngIf="chat.image"
                    class="message-image"
                    id="message-image"
                    [src]="displayImage(chat?.image)"
                  />
                </div>
                <p
                  class="notification-text"
                  id="message-body"
                  *ngIf="chat.message_text.trim().startsWith('Notification:')"
                >
                  {{ chat.message_text.substring(13) }}
                  <!-- Display text after "Notification:" -->
                </p>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="chat-footer">
          <div class="file-container">
            <div class="image-container">
              <input
                id="image"
                type="file"
                (change)="this.onFilechange($event)"
                hidden
              />
              <label for="image">Choose File</label>
            </div>
            <div id="file-chosen">(No file chosen)</div>
          </div>
          <input
            id="chat-textarea"
            [(ngModel)]="chatInputMessage"
            (input)="onTextAreaChange()"
            required
            (keydown.enter)="sendMessage(this.chatInputMessage)"
          />
          <div class="send-button-div" [class.filled]="inputFilled">
            <button
              class="send-button"
              (click)="sendMessage(this.chatInputMessage)"
            >
              <mat-icon
                class="send-icon"
                aria-hidden="false"
                aria-label="Example home icon"
                fontIcon="send"
              ></mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #loadingSpinner>
    <app-loading-spinner
      [fetchMessage]="'Fetching Interaction History...'"
    ></app-loading-spinner>
  </ng-template>
</div>

<div class="empty" *ngIf="noAppeals">
  <h1>No appeals have been initiated</h1>
  <h2>Please check back later</h2>
</div>

<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
/>
<div class="wrapper">
  <div class="container">
    <div class="content">
      <div class="logo">
        <span class="material-symbols-outlined"> rocket_launch </span>
      </div>

      <div class="text-container">
        <h1>Enter your email</h1>
        <p>You will get an email shortly if you are an existing user.</p>
      </div>

      <div class="form-container">
        <form
          [formGroup]="passwordRequestForm"
          (ngSubmit)="onRequestPasswordReset()"
        >
          <mat-form-field>
            <input
              matInput
              id="email"
              type="email"
              formControlName="email"
              placeholder="example@calvin.edu"
            />
            <mat-error
              *ngIf="
              passwordRequestForm.errors?.['required'] &&
              (passwordRequestForm.dirty || passwordRequestForm.touched)
            "
            >
              Please enter a valid @calvin.edu Email
            </mat-error>
          </mat-form-field>

          <button
            mat-raised-button
            type="submit"
            [disabled]="passwordRequestForm.invalid"
          >
            Request Reset Form
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <h1 mat-dialog-title>Sign Out</h1>
  <mat-dialog-content>Are you sure you want to sign out?</mat-dialog-content>
  <mat-dialog-actions>
    <div class="buttons-div">
      <button
        class="blue-button"
        [mat-dialog-close]="true"
        (click)="onSignout()"
      >
        Sign Out
      </button>
      <button class="cancel-button cancel-button" [mat-dialog-close]="false">
        Cancel
      </button>
    </div>
  </mat-dialog-actions>
</div>

<h1 mat-dialog-title>Reset Student Password</h1>
<mat-dialog-content
  ><form [formGroup]="passwordForm" (ngSubmit)="onResetStudentPassword()">
    <mat-form-field>
      <input
        matInput
        id="newPassword"
        type="password"
        formControlName="newPassword"
        placeholder="Password"
      />
      <mat-error *ngIf="passwordForm.get('newPassword')?.hasError('required')">
        Required Field
      </mat-error>
      <mat-error *ngIf="passwordForm.get('newPassword')?.hasError('minlength')">
        Password must be at least 6 characters long
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        id="confirmPassword"
        type="password"
        formControlName="confirmPassword"
        placeholder="Confirm Password"
      />

      <mat-error *ngIf="passwordForm.hasError('passwordMismatch')">
        Passwords do not match
      </mat-error>
    </mat-form-field>
    <mat-dialog-actions>
      <button
        mat-raised-button
        type="submit"
        [disabled]="passwordForm.invalid"
        [ngClass]="passwordForm.invalid ? 'invalid-form' : 'valid-form'"
      >
        Set Password
      </button>
      <button mat-raised-button type="button" [mat-dialog-close]="'cancel'">
        Cancel
      </button></mat-dialog-actions
    >
  </form></mat-dialog-content
>

<div>
  <div
    class="login-wrapper"
    style="
      background-image: url('assets/rocket2.svg');
      background-repeat: no-repeat;
      background-size: cover;
    "
  >
    <button [routerLink]="['about']" class="about-button">
      <mat-icon class="about-icon" fontIcon="?"></mat-icon>
      &ensp;
    </button>
    <div class="form">
      <!-- need to code the reactive form -->
      <p class="welcome">Welcome to</p>
      <p class="gradeboost"><b>GradeBoost!</b></p>

      <div class="row flex-center flex">
        <div class="container" aria-live="polite">
          <p class="description">Log in using your Calvin email</p>
          <form
            [formGroup]="loginForm"
            (ngSubmit)="onLogin()"
            class="form-widget"
          >
            <div>
              <input
                matInput
                id="email"
                formControlName="email"
                class="inputField"
                type="email"
                placeholder="Your email"
              />
              <br />
              <input
                matInput
                id="password"
                formControlName="password"
                class="inputField"
                type="password"
                placeholder="Password"
              />
            </div>
            <div>
              <button
                (click)="onLogin()"
                type="submit"
                class="blue-button"
                [disabled]="loading"
              >
                {{ loading ? "Loading" : "Log In" }}
              </button>
              <p class="register">
                Not registered as an instructor?
                <button class="register-button" (click)="onRegister()">
                  Sign Up
                </button>
              </p>
              <p class="register">
                Forgot your password?
                <button class="register-button" (click)="onRequestToken()">
                  Reset Password
                </button>
              </p>
              <p class="register">
                Token Expired?
                <button class="register-button" (click)="onRequestToken()">
                  Request Token
                </button>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

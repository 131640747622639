<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
/>

<div class="app-container" *ngIf="!loading">
  <div class="top-bar">
    <div class="top-left-content">
      <span
        (click)="navigateTo('student/courses')"
        class="material-symbols-outlined rocket"
      >
        rocket_launch
      </span>
      <span class="title">{{ title }}</span>
    </div>
    <div class="top-right-content">
      <button [routerLink]="['/student/about']" class="about-button">
        <mat-icon
          class="about-icon"
          aria-hidden="false"
          aria-label="Example home icon"
          fontIcon="?"
        ></mat-icon>
        &ensp;
      </button>
      <div class="sign-out-button">
        <span class="sign-out" (click)="toggleLogoutPopup()"> Sign Out </span>
      </div>
    </div>
  </div>

  <div class="sidenav-container">
    <div class="tab-list" >
      <div class="student-label" (click)="toggleTab('student')">
        <mat-icon *ngIf="showStudent">expand_more</mat-icon>
        <mat-icon *ngIf="!showStudent">chevron_right</mat-icon>
        Student</div>
      <div class="student-tab-container" *ngIf="showStudent">
        <div class="tab">
          <button
            class="tab-text sidenav-button"
            [routerLink]="['/student/courses']"
            routerLinkActive="selected"
          >
            <mat-icon
              class="side-nav-icon"
              aria-hidden="false"
              aria-label="Example home icon"
              fontIcon="dashboard"
            ></mat-icon>
            <!-- &ensp;Course Dashboard -->
            <p>Courses</p>
          </button>
        </div>

        <div class="tab">
          <button
            class="sidenav-button"
            [routerLink]="['/student/interaction-history']"
            (click)="navigateTo('student/interaction-history')"
            routerLinkActive="selected"
          >
            <mat-icon
              class="side-nav-icon"
              aria-hidden="false"
              aria-label="Example home icon"
              fontIcon="chat"
            ></mat-icon>
            &ensp;Interaction History
          </button>
        </div>
      </div>
      <div class="grader-label" *ngIf="isGrader" (click)="toggleTab('grader') ">
        <mat-icon *ngIf="showGrader">expand_more</mat-icon>
        <mat-icon *ngIf="!showGrader">chevron_right</mat-icon>
        Grader</div>
      <div class="grader-tab-container" *ngIf="showGrader && !loading">
        <div class="grader-tabs">
          <div class="tab">
            <button
              class="tab-text sidenav-button"
              [routerLink]="['/student/grader/courses']"
              routerLinkActive="selected"
            >
              <mat-icon
                class="side-nav-icon"
                aria-hidden="false"
                aria-label="Example home icon"
                fontIcon="dashboard"
              ></mat-icon>
              &ensp;Courses
            </button>
          </div>
          <div class="tab">
            <button
              class="sidenav-button"
              [routerLink]="['/student/grader/interaction-history']"
              routerLinkActive="selected"
            >
              <mat-icon
                class="side-nav-icon"
                aria-hidden="false"
                aria-label="Example home icon"
                fontIcon="chat"
              ></mat-icon>
              &ensp;Interaction History
            </button>
          </div>
        </div>
      </div>
      <p class="version-number">v{{ versionNumber }}</p>
    </div>
  </div>

  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>

<div class="wrapper">
  <div
    class="container"
    *ngIf="professorAppeals && imageMessages; else loadingSpinner"
  >
    <!-- Left Pane (List of Appeals) -->
    <div class="inbox" *ngIf="professorAppeals.length > 0">
      <div class="search">
        <button type="button" (click)="filterResults(filter.value)">
          <mat-icon
            class="search-icon"
            aria-hidden="false"
            aria-label="Example home icon"
            fontIcon="search"
          ></mat-icon>
        </button>

        <input
          type="text"
          placeholder="Search for Appeal"
          #filter
          (keyup.enter)="filterResults(filter.value)"
          (input)="onInputChange(filter.value)"
        />
      </div>
      <div
        class="inbox-item"
        *ngFor="let appeal of filteredAppeals"
        (click)="selectAppeal(appeal)"
        [class.selected-appeal]="appeal === currentAppeal"
      >
        <div class="left-section">
          <span
            [ngClass]="{
              'unread-dot': appeal.isread === false,
              'no-dot': appeal.isread !== false
            }"
          ></span>
          <!-- <div *ngIf="hasUnreadMessages(appeal)">
            <div class="unread-dot"></div>
          </div> -->
          <p matLine class="inbox-name">
            {{ appeal.student_first_name }} {{ appeal.student_last_name }}
          </p>
          <p class="inbox-assignment-name">
            {{ appeal.course_prefix }}{{ appeal.course_code }}
            {{ appeal.course_section }}
            {{ appeal.course_semester }}
          </p>
          <p class="inbox-assignment-name">{{ appeal.assignment_name }}</p>
        </div>
        <div class="right-side">
          <div class="inbox-item-timestamp-dot-container">
            <p matLine class="inbox-timestamp">
              {{ formatTimestamp(appeal.created_at).date }} <br />
              {{ formatTimestamp(appeal.created_at).time }}
            </p>
            <div
              [ngClass]="{
                'unread-dot': appeal.is_read === false,
                'no-dot': appeal.is_read !== false
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Right Pane (Selected Appeal Details) -->
    <div class="right-pane" *ngIf="imageMessages.length > 0">
      <div class="card">
        <div class="top-bar">
          <div class="left-content">
            <span class="header student-name">
              &nbsp;
              {{
                currentAppeal.student_first_name +
                  " " +
                  currentAppeal.student_last_name
              }} </span
            ><span class="header assignment-name">{{
              currentAppeal.assignment_name
            }}</span>
          </div>
          <div class="right-content">
            <span
              [ngClass]="{
                'green-dot': currentAppeal.grader_id === null,
                'red-dot': currentAppeal.grader_id !== null
              }"
            ></span>
            <span
              class="grader-header"
              *ngIf="currentAppeal.grader_name !== null"
            >
              Grader: {{ currentAppeal.grader_name }}
            </span>
            <span
              class="grader-header"
              *ngIf="currentAppeal.grader_name === null"
            >
              Grader: Unassigned
            </span>

            <button
              (click)="toggleCloseAppealPopup()"
              class="close-appeal-interaction-history cancel-button"
            >
              <p class="button-text">Close Appeal</p>
            </button>
          </div>
        </div>
        <div
          class="chat-list"
          #chatListContainer
          #scrollMe
          [scrollTop]="scrollMe.scrollHeight"
        >
          <ng-container *ngFor="let chat of imageMessages; let i = index">
            <ng-container
              *ngIf="
                i === 0 ||
                !isSameDate(
                  chat.created_at,
                  this.imageMessages[i - 1].created_at
                )
              "
            >
              <div class="section-date">
                {{ formatTimestamp(chat.created_at).date }}
              </div>
            </ng-container>
            <div
              class="chat-item"
              [ngClass]="{
                right: chat?.sender_id == professor.id,
                left: chat?.sender_id != professor.id,
                lightblue: chat?.from_grader == true,
                notification: chat.message_text
                  .trim()
                  .startsWith('Notification:'),
                toGrader: chat?.recipient_id === currentAppeal.grader_id
              }"
            >
              <div
                class="inbox-timestamp"
                *ngIf="!chat.message_text.trim().startsWith('Notification:')"
              >
                <div
                  *ngIf="
                    i === 0 ||
                    chat.sender_id !== this.imageMessages[i - 1]?.sender_id
                  "
                >
                  <p class="name">{{ chat.sender_name }}</p>
                </div>
                {{ formatTimestamp(chat.created_at).time }}
              </div>
              <div class="message-content">
                <div
                  *ngIf="!chat.message_text.trim().startsWith('Notification:')"
                  id="message-body"
                >
                  <p class="message-body">
                    {{ chat?.message_text }}
                  </p>
                </div>
                <div class="image-wrapper">
                  <img
                    *ngIf="chat.image"
                    class="message-image"
                    id="message-image"
                    [src]="displayImage(chat?.image)"
                  />
                </div>

                <p
                  class="message-body"
                  class="notification-text"
                  id="message-body"
                  *ngIf="chat.message_text.trim().startsWith('Notification:')"
                >
                  {{ chat.message_text.substring(13) }}
                  <!-- Display text after "Notification:" -->
                </p>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="chat-footer">
          <div class="buttons">
            <button
              mat-icon-button
              aria-label="Example icon-button with a menu"
              (click)="toggleOptions()"
            >
              <mat-icon class="vert-button">menu</mat-icon>
            </button>

            <div *ngIf="showOptions" class="custom-popup">
              <div>
                <button class="popup-button" [matMenuTriggerFor]="menu">
                  Templates
                </button>
              </div>
              <div>
                <button
                  class="popup-button"
                  *ngIf="currentAppeal.grader_id === null"
                  (click)="onAssignGrader($event)"
                >
                  Assign Grader
                </button>
              </div>
              <div>
                <button
                  class="popup-button"
                  *ngIf="currentAppeal.grader_id !== null"
                  (click)="unassignGrader($event)"
                >
                  Unassign Grader
                </button>
              </div>
              <mat-menu #menu="matMenu">
                <button
                  mat-menu-item
                  *ngFor="let template of professorTemplates"
                  (click)="selectTemplate(template.temp_text)"
                >
                  {{ template.temp_name }}
                </button>
              </mat-menu>
              <div class="image-container">
                <input
                  id="image"
                  type="file"
                  (change)="this.onFilechange($event)"
                  hidden
                />
                <label for="image">Choose File</label>
              </div>
              <div id="file-chosen">(No file chosen)</div>
            </div>
          </div>
          <form class="chat-form">
            <input
              class="chat-textarea"
              name="chat-textarea"
              [(ngModel)]="chatInputMessage"
              required
              (input)="onTextAreaChange()"
              (keydown.enter)="sendMessage(this.chatInputMessage)"
            />
            <div class="grader-slider-whole">
              <mat-slide-toggle
                *ngIf="currentAppeal.grader_id !== null"
                disabled="false"
                class="grader-toggle"
                hideIcon="true"
                (change)="talkToGrader()"
              ></mat-slide-toggle>
              <mat-slide-toggle
                *ngIf="currentAppeal.grader_id === null"
                disabled="true"
                class="grader-toggle"
                hideIcon="true"
                (change)="talkToGrader()"
              ></mat-slide-toggle>
              <p class="grader-toggle-text">To Grader</p>
            </div>
          </form>
          <div class="send-button-div" [class.filled]="inputFilled">
            <button
              class="send-button"
              (click)="sendMessage(this.chatInputMessage)"
            >
              <mat-icon
                class="send-icon"
                aria-hidden="false"
                aria-label="Example home icon"
                fontIcon="send"
              ></mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #loadingSpinner>
    <app-loading-spinner
      [fetchMessage]="'Fetching Interaction History...'"
    ></app-loading-spinner>
  </ng-template>
</div>

<div class="empty" *ngIf="noAppeals">
  <h1>No appeals have been initiated</h1>
  <h2>Please check back later</h2>
</div>

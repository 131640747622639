<!-- Class styles taken from assignment.component.scss -->

<div *ngIf="professorTemplates; else loadingSpinner">
  <div class="edit-templates-container scroll">
    <div class="table-container">
      <table mat-table [dataSource]="templateDataSource">
        <!-- template name -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">
            {{
              element.name.length > 50
                ? element.name.slice(0, 50)
                : element.name
            }}
          </td>
        </ng-container>
        <!-- template text -->
        <ng-container matColumnDef="text">
          <th mat-header-cell *matHeaderCellDef>Template</th>
          <td mat-cell *matCellDef="let element">
            {{
              element.text.length > 200
                ? element.text.slice(0, 200) + "..."
                : element.text
            }}
          </td>
        </ng-container>
        <!-- options for edit and delete -->
        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef>
            {{ "" }}
          </th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <!-- Edit template -->
              <button
                mat-menu-item
                (click)="
                  updateTemplatePopUp(element.id, element.name, element.text)
                "
              >
                <mat-icon>edit</mat-icon>
                <span> Edit</span>
              </button>

              <!-- Delete template -->
              <button mat-menu-item (click)="deleteTemplatePopUp(element.id)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>

  <div class="empty" *ngIf="noTemplates">
    <h1>No templates to display</h1>
    <h2>Add a template using the button below</h2>
  </div>
</div>

<button
  mat-icon-button
  class="global-blue add-button-template"
  (click)="addTemplatePopUp()"
>
  <mat-icon class="add-icon">add</mat-icon>
</button>

<ng-template #loadingSpinner>
  <app-loading-spinner
    [fetchMessage]="'Fetching Templates...'"
  ></app-loading-spinner>
</ng-template>

<!-- closed-appeals.component.html -->
<div class="wrapper">
  <mat-card class="card" *ngIf="closedAppeals; else loadingSpinner">
    <mat-card-content>
      <!-- Closed Appeals List -->

      <div class="scroll" *ngIf="closedAppeals; else loadingSpinner">
        <mat-card
          *ngFor="let appeal of closedAppeals; let i = index"
          class="closed-appeal"
        >
          <div class="text-container">
            <p class="student-name">
              <span class="info-label">Student: </span>
              {{ appeal.student_first_name }} {{ appeal.student_last_name }}
            </p>
            <div class="course-assignment">
              <p class="course-name">
                <span class="info-label">Course: </span>
                {{ appeal.course_prefix }}-{{ appeal.course_code }} -
                {{ appeal.course_name }}
              </p>
              <p><b>Assignment:</b> {{ appeal.assignment_name }}</p>
            </div>
            <p class="text"><b>Text:</b> {{ appeal.appeal_text }}</p>
            <p class="closed-time">
              Closed: {{ formatLocalTimestamp(appeal.last_modified) }}
            </p>
          </div>
          <div class="appeal-info-container">
            <!-- <div class="appeal-info">
              <p><b>Text:</b> {{ appeal.appeal_text }}</p>
            </div> -->
          </div>
          <div class="button-container">
            <button class="blue-button option" (click)="toggleReopenPopup(i)">
              Reopen
            </button>
            <button
              class="cancel-button option"
              (click)="toggleViewAppealPopup(i)"
            >
              View
            </button>
            <button
              class="cancel-button option"
              (click)="toggleDeleteAppealPopup(i)"
            >
              Delete
            </button>
          </div>
        </mat-card>
      </div>
      <ng-template #loadingSpinner>
        <app-loading-spinner
          [fetchMessage]="'Fetching Closed Appeals...'"
        ></app-loading-spinner>
      </ng-template>

      <!-- If there are no closed appeals -->
      <div *ngIf="closedAppeals.length === 0" class="no-appeals-message">
        No closed appeals to display.
      </div>
    </mat-card-content>
  </mat-card>
  <ng-template #loadingSpinner>
    <app-loading-spinner
      [fetchMessage]="'Fetching Closed Appeals...'"
    ></app-loading-spinner>
  </ng-template>
</div>

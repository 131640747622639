<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
/>
<div class="wrapper">
  <div class="container">
    <div class="content">
      <div class="logo">
        <span class="material-symbols-outlined"> rocket_launch </span>
      </div>
      <div class="text-container">
        <h1>Confirmation</h1>
        <p>Enter your email and 6-digit verification token</p>
      </div>
      <div class="form-container">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <mat-form-field>
            <input
              matInput
              formControlName="email"
              type="email"
              placeholder="example@calvin.edu"
              required
            />

            <mat-error
              *ngIf="
                loginForm.get('email')?.invalid &&
                loginForm.get('email')?.touched
              "
            >
              invalid input
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input
              matInput
              formControlName="token"
              type="password"
              placeholder="6-digit verification token"
              required
            />
            <mat-error
              *ngIf="
                loginForm.get('token')?.invalid &&
                loginForm.get('token')?.touched
              "
            >
              invalid input
            </mat-error>
          </mat-form-field>

          <button mat-raised-button color="primary" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

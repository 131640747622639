<div class="component-wrapper">
  <button mat-button type="submit" (click)="onBackButton()" class="blue-button">
    Back
  </button>
  <p class="course-title" *ngIf="course; else loadingSpinner">
    {{ formatCourse(course) }}
  </p>
  <div class="scroll assignments" *ngIf="assignments; else loadingSpinner">
    <table
      *ngIf="assignmentDataSource.length > 0"
      mat-table
      matSort
      (matSortChange)="sortTable($event)"
      [dataSource]="assignmentDataSource"
    >
      <ng-container matColumnDef="assignment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="assignment">
          Assignment
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.assignment }}
        </td>
      </ng-container>
      <ng-container matColumnDef="grader">
        <th mat-header-cell *matHeaderCellDef>Grader</th>
        <td mat-cell *matCellDef="let element">
          {{ element.grader_id ? element.grader_name : "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef>{{ "" }}</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <!-- Delete assignment -->
            <button
              mat-menu-item
              (click)="toggleDeleteAssignmentPopUp(element.id)"
            >
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
            <!-- Edit assignment -->
            <button mat-menu-item (click)="editGraderPopUp(element)">
              <mat-icon>edit</mat-icon>
              <span>Edit Grader</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div *ngIf="assignments.length === 0" class="empty">
      <h1>There are no Assignments in for this class.</h1>
      <h2>Add assignments using the button below</h2>
    </div>
    <div *ngIf="assignments.length === 0" class="empty">
      <h1>There are no Assignments in for this class.</h1>
      <h2>Add assignments using the button below</h2>
    </div>
  </div>
  <button
    mat-icon-button
    class="global-blue add-button-assignment"
    (click)="addAssignmentPopUp(course)"
  >
    <mat-icon class="add-icon">add</mat-icon>
  </button>
</div>

<ng-template #loadingSpinner>
  <app-loading-spinner
    [fetchMessage]="'Fetching Assignment Information...'"
  ></app-loading-spinner>
</ng-template>

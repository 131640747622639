<div
  class="course-wrapper scroll"
  *ngIf="professorCourses; else loadingSpinner"
>
  <mat-card class="course" *ngFor="let course of professorCourses">
    <div class="title-container">
      <mat-card-title class="course-wording"
        >{{ course.prefix }}-{{ course.code }}-{{ course.section }}
        {{ course.semester }}-{{ course.year }}
      </mat-card-title>
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
        class="vert-button"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="toggleDeleteCoursePopup($event, course)">
          <mat-icon>delete-icon</mat-icon>
          <span> Delete Course</span>
        </button>
      </mat-menu>
      <!-- <mat-icon class="delete-icon" (click)="deleteCoursePopUp($event, course)"
        >delete</mat-icon
      > -->
    </div>
    <mat-card-subtitle class="course-name">{{ course.name }}</mat-card-subtitle>
    <button
      type="submit"
      (click)="onViewAssignments(course)"
      class="blue-button"
    >
      View Assignments
    </button>
    <button type="submit" (click)="onViewRoster(course)" class="blue-button">
      View Students
    </button>
  </mat-card>
  <div class="empty" *ngIf="noCourses">
    <h1>No courses to display</h1>
    <h2>Add a course using the button below</h2>
  </div>
  <button
    mat-icon-button
    class="global-blue add-button"
    (click)="addCoursePopUp()"
  >
    <mat-icon class="add-icon">add</mat-icon>
  </button>
</div>

<ng-template #loadingSpinner>
  <app-loading-spinner
    [fetchMessage]="'Fetching Courses...'"
  ></app-loading-spinner>
</ng-template>

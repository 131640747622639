<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
/>
<div class="app-container">
  <div class="top-bar">
    <span class="top-left-content">
      <span
        (click)="navigateTo('/professor/appeal-inbox')"
        class="material-symbols-outlined"
      >
        rocket_launch
      </span>
      <span class="title">{{ title }}</span>
    </span>
    <div class="top-right-content">
      <button [routerLink]="['/professor/about']" class="about-button">
        <mat-icon
          class="about-icon"
          aria-hidden="false"
          aria-label="Example home icon"
          fontIcon="?"
        ></mat-icon>
        &ensp;
      </button>

      <button
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
        class="account-button"
      >
        <mat-icon
          class="account-icon"
          aria-hidden="false"
          aria-label="Example home icon"
          fontIcon="account_circle"
        >
        </mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <!-- Delete assignment -->
        <div class="menu-container">
          <button mat-menu-item [routerLink]="['/professor/account']">
            <mat-icon>settings</mat-icon>
            <span>Settings</span>
          </button>
          <button mat-menu-item (click)="toggleLogoutPopup()">
            <mat-icon>logout</mat-icon>
            <span>Sign Out</span>
          </button>
          <!-- Edit assignment -->
        </div>
      </mat-menu>
    </div>
  </div>

  <div class="sidenav-container">
    <div class="tab-list">
      <div class="tab">
        <button
          class="sidenav-button"
          [routerLink]="['/professor/appeal-inbox']"
          routerLinkActive="selected"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="Example home icon"
            fontIcon="mailbox"
          ></mat-icon>
          &ensp;Appeal Inbox
        </button>
      </div>
      <div class="tab">
        <button
          class="sidenav-button"
          [routerLink]="['/professor/interaction-history']"
          routerLinkActive="selected"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="Example home icon"
            fontIcon="chat"
          ></mat-icon>
          &ensp;Interaction History
        </button>
      </div>
      <div class="tab">
        <button
          class="sidenav-button"
          [routerLink]="['/professor/closed-appeals']"
          routerLinkActive="selected"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="Example home icon"
            fontIcon="delete"
          ></mat-icon>
          &ensp;Closed Appeals
        </button>
      </div>
      <div class="tab">
        <button
          class="sidenav-button"
          [routerLink]="['/professor/courses']"
          routerLinkActive="selected"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="Example home icon"
            fontIcon="person"
          ></mat-icon>
          &ensp;Courses
        </button>
      </div>
      <div class="tab">
        <button
          class="sidenav-button"
          [routerLink]="['/professor/edit-templates']"
          routerLinkActive="selected"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="Example home icon"
            fontIcon="edit"
          ></mat-icon>
          &ensp;Edit Templates
        </button>
      </div>
      <p class="version-number">v{{ versionNumber }}</p>
    </div>
  </div>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>

<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
/>

<div class="wrapper">
  <div class="container">
    <div class="content">
      <div class="logo">
        <span class="material-symbols-outlined"> rocket_launch </span>
      </div>
      <div class="text-container">
        <h1>Reset Password</h1>
        <p>Enter your new password</p>
      </div>
      <div class="form-container">
        <form [formGroup]="passwordForm" (ngSubmit)="onResetPassword()">
          <mat-form-field>
            <input
              matInput
              id="newPassword"
              type="password"
              formControlName="newPassword"
              placeholder="password"
            />
            <mat-error
              *ngIf="
              passwordForm.errors?.['required'] &&
              (passwordForm.dirty || passwordForm.touched)
            "
            >
              Please enter a password
            </mat-error>
            <mat-error *ngIf="passwordForm.errors?.['minLength']">
              Password must be at least 6 characters long
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              id="confirmPassword"
              type="password"
              formControlName="confirmPassword"
              placeholder="Confirm Password"
            />
            <mat-error
              *ngIf="
                passwordForm.get('confirmPassword')?.hasError('required') &&
                passwordForm.get('confirmPassword')?.touched
              "
            >
              Please confirm your password
            </mat-error>
          </mat-form-field>
          <mat-error
            *ngIf="
              passwordForm.hasError('passwordMismatch') &&
              passwordForm.get('newPassword')?.touched &&
              passwordForm.get('confirmPassword')?.touched
            "
          >
            Passwords do not match
          </mat-error>
          <button
            mat-raised-button
            type="submit"
            [disabled]="passwordForm.invalid"
          >
            Set Password
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

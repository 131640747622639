<div class="container" *ngIf="cronForm && password; else loadingSpinner">
  <h2>Welcome, {{ professor.first_name }} {{ professor.last_name }}</h2>
  <div class="card-container">
    <mat-card class="password-form-card">
      <mat-card-header>Change Password</mat-card-header>
      <mat-card-content>
        <mat-form-field appearance="fill">
          <input
            matInput
            placeholder="Password"
            [formControl]="password"
            [type]="hide ? 'password' : 'text'"
          />
          <button
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
          <mat-error *ngIf="password.invalid">
            Password must be at least 6 characters long.
          </mat-error>
        </mat-form-field>
        <div class="button-container">
          <button
            mat-raised-button
            (click)="onChangePassword()"
            [disabled]="password.invalid"
            [ngClass]="password.invalid ? 'gray-button' : 'blue-button'"
          >
            Change Password
          </button>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="cron-form-card">
      <mat-card-header>Email Notification Frequency</mat-card-header>
      <mat-card-content>
        <form [formGroup]="cronForm" (ngSubmit)="onSaveSchedule()">
          <mat-form-field appearance="fill">
            <mat-select formControlName="frequency">
              <mat-option *ngFor="let freq of frequencies" [value]="freq">{{
                freq
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div
            class="week"
            *ngIf="cronForm.get('frequency')?.value === 'weekly'"
          >
            <mat-form-field appearance="fill">
              <mat-label>Day of Week</mat-label>
              <mat-select formControlName="week">
                <mat-option
                  *ngFor="let day of daysOfWeek; let i = index"
                  [value]="i"
                  >{{ day }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div
            class="month"
            *ngIf="cronForm.get('frequency')?.value === 'monthly'"
          >
            <mat-form-field appearance="fill">
              <mat-label>Day of Month</mat-label>
              <mat-select formControlName="month">
                <mat-option
                  *ngFor="let day of dayOfMonth; let i = index"
                  [value]="day"
                  >{{ day }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <mat-card-subtitle>Time</mat-card-subtitle>
          <div class="time">
            <mat-form-field appearance="fill">
              <mat-select formControlName="hour">
                <mat-option
                  *ngFor="let hour of hours; let i = index"
                  [value]="hour"
                  >{{ hour }}</mat-option
                >
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-select formControlName="minute">
                <mat-option *ngFor="let min of minutes" [value]="min">{{
                  min < 10 ? "0" + min.toString() : min
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="button-container">
            <button
              mat-raised-button
              [ngClass]="cronForm.invalid ? 'gray-button' : 'blue-button'"
              type="submit"
              [disabled]="cronForm.invalid"
            >
              Save
            </button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<ng-template #loadingSpinner
  ><app-loading-spinner></app-loading-spinner
></ng-template>

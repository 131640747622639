<!-- Main Content Section -->
<div class="wrapper">
  <div class="container" *ngIf="professorAppeals; else loadingSpinner">
    <!-- Left Pane (List of Appeals) -->
    <div class="inbox" *ngIf="fetchedAppeals">
      <div class="search">
        <button type="button" (click)="filterResults(filter.value)">
          <mat-icon
            class="search-icon"
            aria-hidden="false"
            aria-label="Example home icon"
            fontIcon="search"
          ></mat-icon>
        </button>
        <input
          type="text"
          placeholder="Search for Appeal"
          #filter
          (keyup.enter)="filterResults(filter.value)"
          (input)="onInputChange(filter.value)"
        />
      </div>
      <div
        class="inbox-item"
        *ngFor="let appeal of filteredAppeals"
        (click)="selectAppeal(appeal)"
        [class.selected-appeal]="appeal === currentAppeal"
      >
        <p matLine class="inbox-timestamp">
          {{ formatTimestamp(appeal.created_at).date }} <br />
          {{ formatTimestamp(appeal.created_at).time }}
        </p>
        <div class="inbox-name" matLine>
          <div>
            <b>
              {{ appeal.student_first_name }}
              {{ appeal.student_last_name }}
            </b>
          </div>
          <p class="assignment-name">{{ appeal.assignment_name }}</p>
          <p class="inbox-assignment-name">
            {{ appeal.course_prefix }}{{ appeal.course_code }}
            {{ appeal.course_section }}
            {{ appeal.course_semester }}
          </p>
          <span
            [ngClass]="{
              'green-dot': appeal.grader_id === null,
              'red-dot': appeal.grader_id !== null
            }"
          ></span>
        </div>
      </div>
    </div>

    <!-- Right Pane (Selected Appeal Details) -->
    <div class="right-pane">
      <div *ngIf="!professorAppeals; else elseBlock" class="placeholder-text">
        Select an appeal to view details.
      </div>
      <ng-template #elseBlock>
        <div *ngIf="fetchedAppeals" class="appeal-wrapper">
          <mat-card class="appeal">
            <h2 class="appeal-header">
              <div class="appeal-inbox-header">
                <mat-icon
                  class="header-avatar"
                  aria-hidden="false"
                  aria-label="Example home icon"
                  fontIcon="account_circle"
                ></mat-icon>
                <div class="title">
                  {{ currentAppeal.student_first_name }}
                  {{ currentAppeal.student_last_name }} |
                  {{ currentAppeal.assignment_name }}
                </div>
              </div>
            </h2>
            <p>
              <strong>Course:</strong>
              {{ currentAppeal.course_prefix }}-{{
                currentAppeal.course_code
              }}-{{ currentAppeal.course_section }}
              {{ currentAppeal.course_semester }}-{{
                currentAppeal.course_year
              }}
              <i>{{ currentAppeal.course_name }}</i>
            </p>
            <div class="grader-assignment-container">
              <div
                [ngClass]="{
                  'green-dot': currentAppeal.grader_id === null,
                  'red-dot': currentAppeal.grader_id !== null
                }"
                class="appeal-card"
              ></div>
              <div class="assignment-status-container">
                <div
                  *ngIf="currentAppeal.grader_id !== null"
                  class="assignment-status"
                >
                  Assigned to Grader: {{ currentAppeal.grader_name }}
                </div>
                <div
                  *ngIf="currentAppeal.grader_id === null"
                  class="assignment-status"
                >
                  Assigned to You
                </div>
              </div>
            </div>
            <div class="message-container">
              <p class="message">{{ currentAppeal.appeal_text }}</p>
            </div>
            <div class="buttons">
              <button
                class="interaction-history-button blue-button"
                (click)="
                  navigateTo(
                    'professor/interaction-history/' +
                      this.currentAppeal.appeal_id
                  )
                "
                accent
              >
                <p class="button-text">Reply/View History</p>
              </button>
              <button
                (click)="toggleCloseAppealPopup()"
                class="close-appeal blue-button"
              >
                <p class="button-text">Close Appeal</p>
              </button>

              <button
                *ngIf="currentAppeal.grader_id === null"
                class="grader-button blue-button"
                (click)="onAssignGrader($event)"
              >
                Assign To Grader
              </button>
              <button
                *ngIf="currentAppeal.grader_id !== null"
                class="grader-button blue-button"
                (click)="toggleUnassignGraderPopup()"
              >
                Unassign Grader
              </button>
            </div>
          </mat-card>
        </div>
      </ng-template>
    </div>
  </div>
  <ng-template #loadingSpinner>
    <app-loading-spinner
      [fetchMessage]="'Fetching Appeal Inbox...'"
    ></app-loading-spinner>
  </ng-template>
  <div class="empty-inbox" *ngIf="noAppeals">
    <h1>No appeals have beeen initiated</h1>
    <h2>Please check back later</h2>
  </div>
</div>

<span class="grader-assigned" matSnackBarLabel> Grader already assigned</span>
<span matSnackBarActions>
  <button
    style="color: white"
    mat-button
    matSnackBarAction
    (click)="snackBarRef.dismissWithAction()"
  >
    Dismiss
  </button>
</span>

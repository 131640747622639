<ng-container *ngIf="graders.length > 0; else noGraders">
  <div class="wrapper">
    <h1 class="title">Select a Grader</h1>
    <div class="form-wrapper">
      <mat-form-field class="form">
        <mat-label>Grader</mat-label>
        <mat-select
          panelClass="example-panel-red"
          required="true"
          (selectionChange)="onSelectGrader($event.value)"
        >
          <mat-option *ngFor="let grader of graders" [value]="grader">
            {{ grader.student_name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <p>Message to grader:</p>
      <input class="input" [(ngModel)]="message" />
      <div class="button-div">
        <div class="buttons">
          <button
            class="blue-button"
            (click)="onAssignGrader()"
            [disabled]="!selectedGrader"
          >
            Assign
          </button>
          <button class="cancel-button" [mat-dialog-close]="false">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #noGraders>
  <div class="no-graders">
    <p>No graders for this class. Go to courses to add a grader</p>
    <div class="buttons">
      <button class="cancel-button" [mat-dialog-close]="false">Cancel</button>
      <button
        [mat-dialog-close]="false"
        class="blue-button"
        [routerLink]="['/professor/courses']"
        routerLinkActive="selected"
      >
        Courses
      </button>
    </div>
  </div>
</ng-template>

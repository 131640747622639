<div class="wrapper">
  <div
    class="courses-container scroll"
    *ngIf="studentCourses; else loadingSpinner"
  >
    <mat-card class="course" *ngFor="let course of studentCourses">
      <mat-card-title class="course-name">
        {{ course.course_prefix }}-{{ course.course_code }}-{{
          course.course_section
        }}-{{ course.course_year }}{{ course.course_semester }}
      </mat-card-title>
      <mat-card-subtitle class="course-description">{{
        course.course_name
      }}</mat-card-subtitle>
      <mat-card-subtitle class="course-professor">{{
        course.professor_name
      }}</mat-card-subtitle>
      <div class="buttons">
        <button
          *ngIf="course.is_grader"
          class="blue-button"
          style="color: white"
          (click)="onViewAppeal(course)"
          mat-button
        >
          View Appeals
        </button>
        <!--*ngIf="course.is_grader" -->
        <button
          *ngIf="!course.is_grader"
          class="blue-button"
          style="color: white"
          (click)="onNewAppeal(course)"
          mat-button
        >
          New Appeal
        </button>
      </div>
    </mat-card>
  </div>
  <ng-template #loadingSpinner>
    <app-loading-spinner
      [fetchMessage]="'Fetching Courses...'"
      [spinnerColor]="'white-spinner'"
    ></app-loading-spinner>
  </ng-template>
</div>

<div class="wrapper">
  <div
    class="container"
    *ngIf="graderAppeals && imageMessages; else loadingSpinner"
  >
    <!-- Left Pane (List of Appeals) -->
    <div class="inbox" *ngIf="!noAppeals">
      <div
        class="inbox-item"
        *ngFor="let appeal of graderAppeals"
        (click)="selectAppeal(appeal)"
        [class.selected-appeal]="appeal === currentAppeal"
      >
        <div class="left-section">
          <!-- <div *ngIf="hasUnreadMessages(appeal)">
          <div class="unread-dot"></div>
        </div> -->
          <p matLine class="inbox-name">
            {{ appeal.professor_first_name }} {{ appeal.professor_last_name }}
          </p>
          <p class="inbox-assignment-name">
            {{ appeal.course_prefix }}{{ currentAppeal.course_code }}
            {{ appeal.course_section }}
            {{ appeal.course_semester }}
          </p>
          <p class="inbox-assignment-name">{{ appeal.assignment_name }}</p>
        </div>
        <div class="right-side">
          <div class="inbox-item-timestamp-dot-container">
            <p matLine class="inbox-timestamp">
              {{ formatTimestamp(appeal.created_at).date }} <br />
              {{ formatTimestamp(appeal.created_at).time }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Right Pane (Selected Appeal Details) -->
    <div class="right-pane" *ngIf="imageMessages.length > 0">
      <div class="card">
        <mat-toolbar class="top-bar">
          <div class="left-content">
            <span class="header student-name">
              &nbsp;

              {{ student.first_name + " " + student.last_name }}
            </span>
            <span class="header assignment-name">
              {{ currentAppeal.assignment_name }}</span
            >
          </div>

          <button
            (click)="toggleSendToProfessorPopup()"
            class="cancel-button send-to-professor"
          >
            <p class="button-text">Send To Professor</p>
          </button>
        </mat-toolbar>
        <div
          class="chat-list"
          #chatListContainer
          #scrollMe
          [scrollTop]="scrollMe.scrollHeight"
        >
          <ng-container *ngFor="let chat of imageMessages; let i = index">
            <ng-container
              *ngIf="
                i === 0 ||
                !isSameDate(
                  chat.created_at,
                  this.imageMessages[i - 1].created_at
                )
              "
            >
              <div class="section-date">
                {{ formatTimestamp(chat.created_at).date }}
              </div>
            </ng-container>
            <div
              class="chat-item"
              [ngClass]="{
                right: chat?.sender_id === grader.id,
                left: chat?.sender_id !== grader.id,
                grader: chat?.from_grader === true,
                lightblue: chat?.sender_id === professor.id,
                notification: chat.message_text
                  .trim()
                  .startsWith('Notification:')
              }"
            >
              <div
                class="inbox-timestamp"
                *ngIf="!chat.message_text.trim().startsWith('Notification:')"
              >
                <div
                  *ngIf="
                    i === 0 ||
                    chat.sender_id !== this.imageMessages[i - 1]?.sender_id
                  "
                >
                  <p class="name">{{ chat.sender_name }}</p>
                </div>
                {{ formatTimestamp(chat.created_at).time }}
              </div>
              <div class="message-content">
                <div
                  *ngIf="!chat.message_text.trim().startsWith('Notification:')"
                ></div>

                <div
                  *ngIf="!chat.message_text.trim().startsWith('Notification:')"
                  id="message-body"
                >
                  <p>
                    {{ chat?.message_text }}
                  </p>
                </div>
                <div class="image-wrapper">
                  <img
                    *ngIf="chat.image"
                    class="message-image"
                    id="message-image"
                    [src]="displayImage(chat?.image)"
                  />
                </div>
                <p
                  class="notification-text"
                  id="message-body"
                  *ngIf="chat.message_text.trim().startsWith('Notification:')"
                >
                  {{ chat.message_text.substring(13) }}
                  <!-- Display text after "Notification:" -->
                </p>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="chat-footer">
          <div class="file-container">
            <div class="image-container">
              <input
                id="image"
                type="file"
                (change)="this.onFilechange($event)"
                hidden
              />
              <label for="image">Choose File</label>
            </div>
            <div id="file-chosen">(No file chosen)</div>
          </div>
          <input
            matInput
            id="chat-textarea"
            [(ngModel)]="chatInputMessage"
            (input)="onTextAreaChange()"
            required
            (keydown.enter)="sendMessage(this.chatInputMessage)"
          />
          <div class="send-button-div" [class.filled]="inputFilled">
            <button
              class="send-button"
              (click)="sendMessage(this.chatInputMessage)"
            >
              <mat-icon
                class="send-icon"
                aria-hidden="false"
                aria-label="Example home icon"
                fontIcon="send"
              ></mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #loadingSpinner>
    <app-loading-spinner
      fetchMessage="Fetching Interaction History..."
    ></app-loading-spinner>
  </ng-template>
</div>

<div class="empty" *ngIf="noAppeals">
  <h1>{{ noAppealsMessage }}</h1>
  <h2>Please check back later</h2>
</div>

<div class="page">
  <div class="content">
    <h2 mat-dialog-title>Delete Template</h2>
    <div>Are you sure you want to delete this template?</div>
    <div class="button-div">
      <button mat-button (click)="onDeleteTemplate()">Yes</button>
      <button mat-button [mat-dialog-close]="false">Cancel</button>
    </div>
  </div>
</div>
